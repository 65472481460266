import * as React from "react";
import Layout from "../components/Layout/Layout";
import PersonalCabinet from "../components/personalCabinet/PersonalCabinet";
import BreadCrumbs from "../BreadCrumbs";
import PersonalCabinetSidebar from "../components/personalCabinet/PersonalCabinetSidebar";
import HistoryApplication from "../components/personalCabinet/HistoryApplication/HistoryApplication";
import { useState } from "react";

// @ts-ignore
const cabinet = ({ pageContext }) => {
  const [showAnalysisTitle, setShowAnalysisTitle] = useState(`Results`);

  return (
    <Layout>
      <div className="flex items-center justify-center bg-[#FAFAFA]">
        <div className="flex flex-col  xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px]">
          <div className=" mb-10 flex flex-col justify-start ">
            <div className="mt-[2.5rem] mb-[2.5rem]">
              фывфыв
              <BreadCrumbs pageContext={pageContext} title={"Личный кабинет"} />
            </div>
            <div className="w-[100%] flex   gap-16 mb-[2.5rem]">
              ывафвафыва
              <h2 className="text-[2rem] text-[#001439] font-semibold ">
                Иванов Иван Иванович фывыф
              </h2>
            </div>

            <div className="w-[100%] flex  justify-between gap-10 xs:flex-col sm:flex-col lg:flex-row">
              <PersonalCabinetSidebar
                setShowAnalysisTitle={setShowAnalysisTitle}
                showAnalysisTitle={showAnalysisTitle}
              />

              {/*<Results />*/}
              <HistoryApplication />
              {/*<MyAccount />*/}
              {/*<EditAccount />*/}
              {/*<FamilyAccount />*/}
              {/*<ConfirmationLetter />*/}
              {/*<FamilyAccountEdit />*/}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default cabinet;
